<template>
  <validation-observer ref="simpleRules">
    <b-form>
    <div>
      <b-card>
        <b-row>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Rut Empresa
            </h5>


            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Rut Empresa"
            >
              <b-form-input
                  v-model="rut_empresa"
                  :state="rut_empresa.length > 0"
                  placeholder="12346678-9"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Nombre Fantasía
            </h5>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Nombre Fantasía"
            >
              <b-form-input
                  v-model="nombre_fantasia"
                  :state="nombre_fantasia.length > 0"
                  placeholder="Nombre Fantasia"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Razón Social
            </h5>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Razón Social"
            >
              <b-form-input
                  v-model="razon_social_empresa"
                  :state="razon_social_empresa.length >0"
                  placeholder="Ingrese Razon Social identificador de la empresa"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Holding
            </h5>
            <autocomplete-infinity
              url="entidades/holding"
              placeholder="Seleccione Holding"
              label="nombre_holding"
              items-per-page="10"
              @item-selected="selectHolding"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Descripción Empresa
            </h5>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Descripción Empresa"
            >
              <b-form-textarea
                  v-model="descripcion_empresa"
                  :state="descripcion_empresa.length >0"
                  placeholder="Ingrese observaciones o referencias de la empresa a registrar.Estas observaciones pueden servir para recordar de que empresa se trata en caso que no se acuerde bien."
                  rows="3"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Giro Empresa
            </h5>
            <b-form-input
              id="h-first-name"
              v-model="giro_empresa"
              placeholder="Giro"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Comuna
            </h5>
            <autocomplete-infinity
              url="entidades/comuna"
              placeholder="Seleccione Comuna"
              label="nombre_comuna"
              items-per-page="10"
              @item-selected="selectComuna"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Teléfono Temporal
            </h5>
            <b-form-input
              id="telefono_temporal"
              v-model="telefono_temporal"
              placeholder="Teléfono temporal"
              :state="telefono_temporal_state"
              type="number"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Dirección Factura
            </h5>
            <b-form-input
              id="direccion_factura"
              v-model="direccion_factura"
              placeholder="Dirección Factura"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Comuna Factura
            </h5>
            <autocomplete-infinity
              url="entidades/comuna"
              placeholder="Seleccione Comuna"
              label="nombre_comuna"
              items-per-page="10"
              @item-selected="selectComunaFactura"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Dirección Despacho
            </h5>
            <b-form-input
              id="direccion_despacho"
              v-model="direccion_despacho"
              placeholder="Dirección despacho"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Email para SII
            </h5>
            <b-form-input
              id="mail_sii"
              v-model="mail_sii"
              placeholder="Email para SII"
              type="email"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Nombre Contacto Cobranza
            </h5>
            <b-form-input
              id="nombre_contacto_cobranza"
              v-model="nombre_contacto_cobranza"
              placeholder="Nombre contacto cobranza"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Email Contacto
            </h5>
            <b-form-input
              id="mail_contacto_cobranza"
              v-model="mail_contacto_cobranza"
              placeholder="Email contacto cobranza"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Teléfono Contacto Cobranza
            </h5>
            <b-form-input
              id="telefono_contacto_cobranza"
              v-model="telefono_contacto_cobranza"
              placeholder="Teléfono contacto cobranza"
              type="number"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Ejecutivo al que pertenece
            </h5>
            <autocomplete-infinity
              url="entidades/ejecutivo"
              placeholder="Seleccione Ejecutivo"
              label="fullname"
              items-per-page="10"
              @item-selected="selectEjecutivo"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Observaciones glosa facturación
            </h5>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Observaciones glosa facturación"
            >
              <b-form-textarea
                  v-model="observaciones_glosa_facturacion"
                  :state="observaciones_glosa_facturacion.length >0"
                  placeholder="Ingrese observaciones o referencias de la empresa a registrar.Estas observaciones pueden servir para recordar de que empresa se trata en caso que no se acuerde bien."
                  required
                  rows="3"
                  type="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Opciones
            </h5>
            <div class="demo-inline-spacing">

              <b-form-checkbox
                v-model="requiere_orden_compra"
                value="1"

                class="custom-control-primary"
              >
                Requiere Orden de Compra
              </b-form-checkbox>
              <b-form-checkbox
                v-model="requiere_hess"
                value="1"

                class="custom-control-primary"
              >
                Requiere Hess
              </b-form-checkbox>
              <b-form-checkbox
                v-model="requiere_numero_contrato"
                value="1"

                class="custom-control-primary"
              >
                Requiere número contrato
              </b-form-checkbox>
              <b-form-checkbox
                v-model="requiere_ota"
                value="1"

                class="custom-control-primary"
              >
                Requiere OTA
              </b-form-checkbox>
            </div>
          </b-col>
          <!-- Enviar y Limpiar -->
          <b-col offset-md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              @click="atras"
            >
              Cancelar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="outline-primary"
              @click.prevent="validationForm"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>

      </b-card>
    </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInput,
  VBModal,
  BLink,
  BFormFile,
  BPagination,
  BSpinner,
  VBTooltip,
  BFormTextarea,
  BCardText,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'AgregarEmpresa',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      showTable: true,
      perPage: 50,
      reportType: 1,
      currentPage: 1,
      page: 1,
      list: [],
      ordenCompra: null,
      hess: null,
      numeroContrato: null,
      requiereOta: null,
      motivoRechazo: [],
      idRectificacion: 0,
      dataResponse: [],
      filter: {
        holding: null,
        nombre_empresa: null,
        rut_empresa: null,
        item_per_page: 50,
        page: 1,
      },
      fields: [
        { key: 'id_empresa', label: 'ID', display: true },
        { key: 'rut_empresa', label: 'Rut', display: true },
        { key: 'razon_social_empresa', label: 'Razon Social', display: true },
        { key: 'giro_empresa', label: 'Giro', display: true },
        { key: 'direccion_empresa', label: 'Direccion', display: true },
        { key: 'id_holding', label: 'Holding', display: true },
        { key: 'modalidad', label: 'Ventas', display: true },
        { key: 'empresa', label: 'Contacto', display: true },
        { key: 'estado_empresa', label: 'Estado', display: true },
        {
          key: 'accion', label: 'Acciones', display: true, tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      selectTipoHolding: null,

      rut_empresa: '',
      id_empresa: '',
      nombre_fantasia: '',
      razon_social_empresa: '',
      descripcion_empresa: '',
      giro_empresa: '',
      telefono_temporal: '',
      direccion_factura: '',
      id_comuna_facturacion: '',
      direccion_despacho: '',
      mail_sii: '',
      nombre_contacto_cobranza: '',
      mail_contacto_cobranza: '',
      telefono_contacto_cobranza: '',
      observaciones_glosa_facturacion: '',
      id_holding: '',
      direccion_empresa: '',
      id_comuna: '',
      requiere_orden_compra: 0,
      requiere_hess: 0,
      requiere_numero_contrato: 0,
      requiere_ota: 0,

      dataEmpresa: {
        id_empresa: '',
        rut_empresa: '',
        razon_social_empresa: '',
        giro_empresa: '',
        direccion_empresa: '',
        id_holding: 1,
        nombre_fantasia: '',
        descripcion_empresa: '',
        telefono_temporal: '',
        direccion_factura: '',
        direccion_despacho: '',
        mail_sii: '',
        nombre_contacto_cobranza: '',
        mail_contacto_cobranza: '',
        telefono_contacto_cobranza: '',
        observaciones_glosa_facturacion: '',
        id_comuna: '',
        requiere_orden_compra: 0,
        requiere_hess: 0,
        requiere_numero_contrato: 0,
        requiere_ota: 0,
        id_comuna_facturacion: '',

      },

      pageOptions: [50, 100, 500, 1000],
      transProps: {
        // Transition name
        name: 'flip-list',
      },
    }
  },
  computed: {
    isBusy() {
      return this.$store.state.empresa.isBusy
    },
    mensajeRespuestaAccion() {
      console.log('mensajeRespuestaAccion', this.$store.state.empresa.mensajeRespuesta)
      this.mensaje = this.$store.state.empresa.mensajeRespuesta
      console.log('MEnsaje', this.mensaje)
      this.$bvToast.toast({
        title: this.mensaje.titulo,
        variant: 'success',
        solid: true,
        message: this.mensaje.mensaje,
      })
    },
  },
  watch: {},
  mounted() {
  },
  methods: {

    enviarDatosEmpresa() {
      this.dataEmpresa.id_empresa = this.id_empresa

      this.dataEmpresa.nombre_fantasia = this.nombre_fantasia
      this.dataEmpresa.razon_social_empresa = this.razon_social_empresa
      this.dataEmpresa.descripcion_empresa = this.descripcion_empresa
      this.dataEmpresa.giro_empresa = this.giro_empresa
      this.dataEmpresa.telefono_temporal = this.telefono_temporal
      this.dataEmpresa.direccion_factura = this.direccion_factura
      this.dataEmpresa.direccion_despacho = this.direccion_despacho
      this.dataEmpresa.mail_sii = this.mail_sii
      this.dataEmpresa.id_holding = this.id_holding
      this.dataEmpresa.direccion_empresa = this.direccion_empresa
      this.dataEmpresa.id_comuna = this.id_comuna
      this.dataEmpresa.nombre_contacto_cobranza = this.nombre_contacto_cobranza
      this.dataEmpresa.mail_contacto_cobranza = this.mail_contacto_cobranza
      this.dataEmpresa.telefono_contacto_cobranza = this.telefono_contacto_cobranza
      this.dataEmpresa.observaciones_glosa_facturacion = this.observaciones_glosa_facturacion
      this.dataEmpresa.requiere_orden_compra = this.requiere_orden_compra
      this.dataEmpresa.requiere_hess = this.requiere_hess
      this.dataEmpresa.requiere_numero_contrato = this.requiere_numero_contrato
      this.dataEmpresa.requiere_ota = this.requiere_ota
      if (this.validarRUt(this.rut_empresa)) {
        this.dataEmpresa.rut_empresa = this.rut_empresa
        alert("ENVIADOO")
        this.$store.dispatch('empresa/agregarEditarEmpresa', this.dataEmpresa)
        this.$router.push({name: 'empresa' })
      } else {
        this.$bvToast.toast('Favor verificar el Rut', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }

    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          this.enviarDatosEmpresa()
        }
      })
    },
    atras() {
      window.history.back()
    },
    validarRUt(rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false
      const tmp 	= rutCompleto.split('-')
      let digv	= tmp[1]
      const rut 	= tmp[0]
      if (digv == 'K') digv = 'k'
      return (this.validarDvRut(rut) == digv)
    },
    validarDvRut(T) {
      let M = 0; let S = 1
      for (;T; T = Math.floor(T / 10)) S = (S + T % 10 * (9 - M++ % 6)) % 11
      return S ? S - 1 : 'k'
    },

    selectHolding(holding) {
      console.log('selectHolding', holding)
      this.nuevoContacto.id_nivel_contacto = holding != null ? holding.id_holding : null
    },
    selectEjecutivo(ejecutivo) {
      console.log('selectHolding', ejecutivo)
      this.nuevoContacto.id_nivel_contacto = ejecutivo != null ? ejecutivo.id_holding : null
    },
    selectComuna(comuna) {
      console.log('selectComuna', comuna)
      this.dataEmpresa.id_comuna = comuna != null ? comuna.id_comuna : null
    },
    selectComunaFactura(comuna) {
      console.log('selectComunaFactura', comuna)
      this.dataEmpresa.id_comuna_facturacion = comuna != null ? comuna.id_comuna : null
    },

    validarCorreoOrTelefono(correo, telefono){
      console.log("validarCorre",correo);
      console.log("validarCorreoOrTelefono",telefono);
      if(correo =='' && telefono ==''){
        this.$bvToast.toast('Es requerido telefono o correo ', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return false
      }
      return true
    },

  },
}

</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px!important;
  color: #6e6b7b;
}
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Browse';
}
</style>
